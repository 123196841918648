import React from 'react';
import { Link } from 'gatsby';

import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from '../components/image'

import background from "../images/feature-audiences.png"
import backgroundSmall from "../images/feature-audiences-sm.png"

import SignupModalTrigger from '../components/home/signupModal'


const Home = ({history, location, match, path}) => {
  // const [hideFooter, setHideFooter] = useState(true);

  var loginUrl = `http://localhost:3000/`;
  if (process.env.GATSBY_APPLICATION_URL){
    loginUrl = `${process.env.GATSBY_APPLICATION_URL}/`
  } else if (process.env.GATSBY_RENDER_FRONTEND_SERVICE){
    loginUrl = `https://${process.env.GATSBY_RENDER_FRONTEND_SERVICE}.onrender.com/`
  }

  return (
    <Layout
      fullWidth={true}
      hideHeader={true} // b/c we're including it in the banner ourselves
      hideFooter={true}
      seoComponent={<SEO title={'GummySearch Audience Research'} path={path} />}
    >
      <main>
        <section className="min-h-screen font-dm-sans relative">
          <div className="absolute w-full h-full z-10 opacity-0 sm:opacity-20 bg-cover bg-no-repeat lg:bg-cover bg-center"
            style={{ backgroundImage: `url(${background})` }}></div>
          {/*<div className="absolute w-full h-full z-10 opacity-20 sm:opacity-0 bg-contain bg-no-repeat lg:bg-cover bg-center"
            style={{ backgroundImage: `url(${backgroundSmall})` }}></div>*/}
          {/*<div className="absolute h-full w-full bg-cyan-100 z-0 opacity-0"></div>*/}
          
          <div className="absolute h-full w-full z-10 p-4">
            
            <div className="px-2 sm:px-6 bg-cover min-h-screen bg-trans-grad md:px-0 py-0">
              <div className="flex items-center justify-center min-h-screen w-full">
                <div className="md:min-w-[600px] max-w-[600px] px-4 md:px-0 bg-gray-800 rounded-2xl shadow-2xl">
                  
                  <div className="py-10 pb-8 text-center max-w-[480px] m-auto">
                    <h1 className="font-extrabold text-3xl sm:text-4xl leading-normal">
                      Your <span className="bg-cyan-600 text-white px-2 -mx-1 rounded-lg">customers</span>{' '}
                      are <span className="inline-block sm:hidden">talking</span> on <span className="bg-cyan-600 text-white px-2 -mx-1 rounded-lg"  style={{'backgroundColor': '#FF5700'}}>Reddit</span>
                    </h1>
                    <p className="text-lg sm:text-xl mt-4 text-gray-300">
                      <span className="hidden sm:inline-block">You can now</span>{' '}
                      find, organize, and search their communities
                    </p>
                    <hr className="my-8 border-gray-700" />

                    <div className="mt-4 flex text-sm space-x-4 text-gray-300">
                      <div className="flex-1 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto text-green-400 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                        </svg>
                        <div>Ideate Startups</div>
                      </div>
                      <div className="flex-1 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto text-green-400 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                        <div>Validate Products</div>
                      </div>
                      <div className="flex-1 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto text-green-400 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                        <div>Inspire Content</div>
                      </div>
                      <div className="flex-1 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 mx-auto text-green-400 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div>Find Sales Leads</div>
                      </div>
                    </div>

                    <hr className="my-8 border-gray-700" />

                    <div className="flex items-center space-x-8 justify-center">
                      <div className="flex justify-center">
                        <SignupModalTrigger initiator="landing-splash">
                          <div className="flex items-center font-bold inline-block cursor-pointer justify-center w-full py-4 px-8 text-white rounded-lg bg-green-500 hover:opacity-90">
                            <span>Sign up for free</span>
                          </div>
                        </SignupModalTrigger>
                      </div>
                      <div className="text-center">
                        <Link to="/product/" className="font-medium text-gray-300 p-4 hover:text-gray-200">Tell me more</Link>
                      </div>
                    </div>
                  </div>
                   <div className="text-gray-400 text-center pb-4">Have an account? <a href={loginUrl} className="underline text-gray-200">Log In</a></div>
                </div>

              </div>
              
            </div>
          </div>

          <div className="absolute z-20 bottom-8 width-full left-8 sm:left-auto right-8 text-white flex space-x-2">
            <div className="mx-auto text-sm flex space-x-6 sm:space-x-8">
              <Link to="/product/" className="font-medium text-gray-300 hover:text-gray-200">Product</Link>
              <Link to="/pricing/" className="font-medium text-gray-300 hover:text-gray-200">Pricing</Link>
              <Link to="/reviews/" className="font-medium text-gray-300 hover:text-gray-200">Reviews</Link>
              <Link to="/tools/" className="font-medium text-gray-300 hover:text-gray-200">Tools</Link>
              <Link to="/blog/" className="font-medium text-gray-300 hover:text-gray-200">Blog</Link>
              <a href={loginUrl} className="font-medium text-gray-300 hover:text-gray-200">Login</a>
            </div>
          </div>

        </section>
      </main>
    </Layout>
  );
}

export default Home;
